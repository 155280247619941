<template>
  <div class="nar">
    <div class="topnar pcbig">
      <div class="top_left">
        <img
          class="topimg"
          @click="goindex('首页')"
          src="../assets/icon/logo@2x.png"
          alt=""
        />
      </div>

      <div class="top_right">
        <div class="narlist">
          <div class="search" v-if="searchShow">
            <input
              class="searchinput"
              placeholder="请输入搜索内容"
              v-model="inptext"
              type="text"
              @keydown="seceinpt"
            />
            <div class="search_box" @click="clikseceinpt">
              <img class="search_img" src="../assets/icon/20_dss@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="logtext">
          <div class="logo_left" v-if="!userinfo.login_phone">
            <div class="log_secel" style="padding: 0 20px" @click="goMask(1)">
              <img class="userimg" src="../assets/icon/huiyu_ya@2x.png" alt="" />
              <div class="logon">会员登录</div>
            </div>
          </div>
          <div class="logo_left" v-if="userinfo.login_phone">
            <div class="log_secel" @click="gouser">
              <img class="userimg" src="../assets/icon/huiyu_ya@2x.png" alt="" />
              <div class="logonText">
                尊敬的 {{ userinfo.login_phone ? userinfo.login_phone : "用户昵称" }}
              </div>
            </div>
          </div>
          <div class="log_exit" v-if="userinfo.login_phone" @click.stop="cleartoken">
            退出登录
          </div>
          <!-- <div class="logon_hover">
            <img
              class="logon_img"
              @click="popcome"
              src="../assets/img/dianhua_hh@2x.png"
              alt=""
            />
            <div class="contact">
              <div class="contact_box">
                <div class="contact_title">扫一扫，联系我们</div>
                <img class="contact_img" :src="configdata.ercode" alt="" />
                <div>联系电话：{{ configdata.tel }}</div>
              </div>
            </div>
          </div> -->
          <el-button
            @click.stop="drawer = true"
            type="primary"
            style="margin-left: 16px"
            class="pcmin"
          >
          </el-button>
        </div>
      </div>
    </div>

    <div class="login" v-show="loginshow">
      <div class="loigin_masking">
        <img class="leftimg" src="../assets/img/tupian_zs.png" alt="" />
        <div class="right_wif">
          <div id="login_container"></div>
        </div>
        <img class="del_img" @click="ckclose" src="../assets/icon/guanb_ad.png" alt="" />
      </div>
      <div class="black"></div>
    </div>
    <div class="login phone" v-if="phoneShow">
      <div class="loigin_masking">
        <div class="right_wif">
          <div class="title_wi">招商资料库普通会员登录</div>
          <div class="title_tips">微信扫码完成，根据有关规定，必须绑定手机</div>
          <div class="account_number">
            <img class="cionimg codeimg" src="../assets/img/miam_m@2x.png" alt="" />
            <input
              class="inp"
              placeholder="请输入手机号码"
              v-model="finddata.login_phone"
              type="number"
            />
          </div>
          <div class="account_number" style="margin-top: 20px">
            <img class="cionimg phoneimg" src="../assets/img/yanz_dm@2x.png" alt="" />
            <div class="inputbuton">
              <input
                class="inp"
                v-model="finddata.code"
                placeholder="请输入验证码"
                type="text"
              />
              <div @click="phonecode" class="obtaincode">
                {{ second != 0 ? second : "获取验证码" }}
              </div>
            </div>
          </div>
          <div class="submit" @click="sumintlogo">登录</div>
        </div>
        <!-- <img class="del_img" @click="ckphnoe" src="../assets/icon/guanb_ad.png" alt="" /> -->
      </div>
      <div class="black"></div>
    </div>
  </div>
</template>

<script>
import Bus from "@/js/bus";
import { gotcode, getenroll, mobilePhoneLogin, usersinfo, wxLogin } from "@/api/logoin";
import { commonconfig } from "@/api/index";
export default {
  name: "topnar",
  props: {
    nar: String,
    logshow: Boolean,
  },
  data() {
    return {
      loginslce: 1,
      loginshow: false,
      phoneShow: false,
      drawer: false,
      narnumber: "",
      secondre: 0,
      second: 0,
      checked: false,
      searchShow: true,
      finddata: {
        login_phone: "",
        code: "",
      },
      slesdata: {
        login_phone: "",
        code: "",
        nick_name: "",
      },
      userinfo: {},
      timereenomor: true,
      nardata: [],
      configdata: {},
      inptext: "",
    };
  },
  watch: {
    logshow() {
      //materielId为需要监听的data
      // this.loginshow = true;
      this.ckclose();
    },
  },
  mounted() {
    this.userinfoun();
  },
  created() {
    let that = this;
    let val = window.location.search;
    let token = sessionStorage.getItem("cc_token");
    if (val && !token) {
      let obj = this.getUrlParams(val);
      if (obj.code) {
        wxLogin({
          code: obj.code,
          source: 3,
        })
          .then((r) => {
            console.log(r);
            sessionStorage.setItem("cc_token", r.data.data.token);
            if (!r.data.data.login_phone) {
              that.phoneShow = true;
            } else {
              that.userinfo = r.data.data;
            }
          })
          .catch((e) => {});
      }
    }
    Bus.$on("getTarget", () => {
      this.goMask(1);
      //此处执行对应的函数操作
    });
    window.addEventListener("login", () => {
      this.loginshow = true;
    });
    window.addEventListener("user", () => {
      this.userinfoun();
    });
    window.addEventListener("searchShowTr", () => {
      this.searchShow = true;
    });
    window.addEventListener("searchShowFa", () => {
      this.searchShow = false;
    });
  },
  methods: {
    getUrlParams(url) {
      console.log(url);
      let urlStr = url.split("?")[1];
      let obj = {};
      let paramsArr = urlStr.split("&");
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        let arr = paramsArr[i].split("=");
        obj[arr[0]] = arr[1];
      }
      return obj;
    },
    seceinpt(e) {
      if (e.keyCode == 13) {
        this.$router.push({
          path: "search",
          query: { sease: this.inptext },
        });
      }
    },
    clikseceinpt() {
      this.$router.push({
        path: "search",
        query: { sease: this.inptext },
      });
    },
    celseckbox() {
      if (this.checked == true) {
        this.checked = false;
      } else {
        this.checked = true;
      }
    },
    leftclo() {
      this.drawer = false;
    },
    popcome() {},
    cleartoken() {
      sessionStorage.removeItem("cc_token");
      let currentPath = window.location.pathname;
      this.userinfoun();
      if (currentPath != "/index/") {
        this.$router.push({
          path: "/",
        });
      } else {
        return;
      }
    },

    userinfoun() {
      usersinfo({}).then((res) => {
        this.userinfo = res.data.data;
      });
      commonconfig({
        id: 30,
      }).then((res) => {
        this.configdata = res.data.data;
      });
    },
    goDatlit() {
      let currentPath = window.location.pathname;
      if (currentPath != "/fundTerms") {
        this.$router.push({
          path: "/fundTerms",
        });
      }
    },
    phonecode() {
      let myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.finddata.login_phone && myreg.test(this.finddata.login_phone)) {
        gotcode({
          mobile_phone: this.finddata.login_phone,
          sms_type: 1,
        })
          .then((res) => {
            if (this.secondre == 0) {
              this.timere();
            }
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    getselecode() {
      let myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.slesdata.login_phone && myreg.test(this.slesdata.login_phone)) {
        gotcode({
          login_phone: this.slesdata.login_phone,
        })
          .then((res) => {
            if (this.second == 0) {
              this.timere();
            }
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    timeree() {
      this.secondre = 60;
      let _this = this;
      var timer = setInterval(function () {
        if (_this.secondre === 0) {
          clearInterval(timer);
        } else {
          _this.secondre--;
        }
      }, 1000);
    },
    timere() {
      this.second = 60;
      let _this = this;
      var timerr = setInterval(function () {
        if (_this.second === 0) {
          clearInterval(timerr);
        } else {
          _this.second--;
        }
      }, 1000);
    },
    gorigth() {
      this.loginslce = 2;
    },
    gologin() {
      this.loginslce = 1;
    },
    reyego() {
      let myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(this.slesdata.login_phone);
      if (this.slesdata.login_phone && myreg.test(this.slesdata.login_phone)) {
        this.loginslce = 3;
        getenroll(this.slesdata)
          .then((res) => {})
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    sumintlogo() {
      let myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.finddata.login_phone && myreg.test(this.finddata.login_phone)) {
        mobilePhoneLogin(this.finddata)
          .then((res) => {
            this.userinfoun();
            this.loginshow = false;
            this.phoneShow = false;
          })
          .catch((err) => {
            this.$message({
              message: "网络请求超时",
              iconClass: "true",
              center: "true",
            });
            return;
          });
      } else {
        this.$message({
          message: "请填写正确的手机号",
          iconClass: "true",
          center: "true",
        });
        return;
      }
    },
    relognin() {
      this.loginslce = 1;
    },
    gosummti() {
      let currentPath = window.location.pathname;
      if (currentPath != "/index/summit") {
        this.$router.push("/summit");
      }
    },
    goitem(val) {
      let currentPath = window.location.pathname;
      if (currentPath != "/pc" + val) {
        this.$router.push(val);
      }
    },
    gofof() {
      let currentPath = window.location.pathname;
      if (currentPath != "/index/fofobserve") {
        this.$router.push("/fofobserve");
      }
    },
    gouser() {
      let currentPath = window.location.pathname;
      if (currentPath != "/index/admin") {
        this.$router.push("/admin");
      }
    },
    ckclose() {
      if (this.loginshow == true) {
        this.loginshow = false;
      } else {
        this.loginshow = true;
      }
    },
    ckphnoe() {
      if (this.phoneShow == true) {
        this.phoneShow = false;
      } else {
        this.phoneShow = true;
      }
    },
    goindex(item) {
      this.narnumber = item;
      let currentPath = window.location.pathname;
      if (item == "首页") {
        if (currentPath != "/index/") {
          this.$router.push({
            path: "/",
          });
          return;
        }
        return;
      } else if (item == "公示") {
        if (currentPath != "/index/publicity") {
          this.$router.push({
            path: "/publicity",
          });
          return;
        }
        return;
      } else if (item == "申报") {
        if (currentPath != "/index/declare") {
          this.$router.push({
            path: "/declare",
          });
          return;
        }
        return;
      } else if (item == "延续") {
        if (currentPath != "/index/continuee") {
          this.$router.push({
            path: "/continuee",
          });
          return;
        }
        return;
      } else if (item == "变更") {
        if (currentPath != "/index/change") {
          this.$router.push({
            path: "/change",
          });
          return;
        }
        return;
      } else if (item == "展示") {
        if (currentPath != "/index/blueLake") {
          this.$router.push({
            path: "/blueLake",
          });
          return;
        }
        return;
      }
    },
    goMask(val) {
      this.loginshow = true;

      // this.$emit("change", 1);
      // this.phoneShow = true;
      this.loginslce = val;
      var obj = new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wx2bc609e23cd693ed",
        scope: "snsapi_login",
        redirect_uri: encodeURI("http://zhaoshangziliao.com/index/"),
        href:
          "LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI0MHB4O21hcmdpbi10b3A6MjVweDtib3JkZXI6MH0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyNDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTpub25lfQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7cGFkZGluZzowfQouaW1wb3dlckJveCAuaW5mb3ttYXJnaW46IC0xMHB4IGF1dG99Cg==",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/.el-checkbox__inner {
  border-radius: 50px;
}
/deep/.el-button {
  background: none !important;
  border: none !important;
  padding: 0px !important;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}

.logo_left:hover {
  .log_exit {
    display: block !important;
  }
}
.logo_left:hover {
  color: #472d86 !important;
}
.logtext:hover .log_exit {
  display: flex !important;
}
.logtext {
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
  /* prettier-ignore */
  font-size: 18px;
  // font-size: calc(1.2vw + 1.4vh + 0.6vmin);
  height: 100%;
  position: relative;

  .log_exit {
    position: absolute;
    /* prettier-ignore */
    bottom: -60px;
    z-index: 90;
    /* prettier-ignore */
    width:197px;
    /* prettier-ignore */
    height: 50px;
    border-radius: 2px;
    display: none;
    justify-content: center;
    align-items: center;
    background: #d46262;
    color: #fff;
  }
  .iconimg {
    width: 100px;
    height: 100px;
  }
  .logo_left {
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* prettier-ignore */
    font-size: 14pX;
    .log_secel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      width: 197px;
      height: 60px;
      background: #dd1d1e;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      /* prettier-ignore */
      font-size: 20px;
      color: #ffffff;
      /* prettier-ignore */
      padding: 0 10px;
      box-sizing: border-box;
      .logonText {
        font-family: PingFangSC, PingFang SC;
        /* prettier-ignore */
        font-weight: 400;
        /* prettier-ignore */
        font-size: 15px;
        color: #ffffff;
      }
      .userimg {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .logo_img {
      width: 28px;
      height: 28px;
      margin-left: 10px;
      border-radius: 50%;
    }
    .log_exit {
      /* prettier-ignore */
      width: 100pX;
      /* prettier-ignore */
      height: 40pX;
      /* prettier-ignore */
      line-height: 40pX;
      text-align: center;
      position: absolute;
      /* prettier-ignore */
      bottom: -50pX;
      left: 50%;
      /* prettier-ignore */
      margin-left: -50pX;
      background: #472d86;
      color: #fff;
      border-radius: 5px;
      z-index: 999;
      display: none;
    }
  }

  .logon_img {
    width: 45px;
    height: 45px;
    /* prettier-ignore */
    margin-left: 20px;
  }
}
.phone {
  .loigin_masking {
    .right_wif {
      /* prettier-ignore */
      width: 784px !important;
      /* prettier-ignore */
      height: 410px !important;
      background: #ffffff;
      border-radius: 4px;
      .title_wi {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        /* prettier-ignore */
        font-size: 20px;
        color: #333333;
        line-height: 30px;
      }
      .title_tips {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        /* prettier-ignore */
        font-size: 14px;
        color: #333333;
        line-height: 30px;
        /* prettier-ignore */
        margin-bottom: 45px;
      }
      .submit {
        /* prettier-ignore */
        width: 330pX;
        /* prettier-ignore */
        height: 50pX;
        background: #dd1d1e;
        border-radius: 4px;
        /* prettier-ignore */
        margin-top: 30px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        /* prettier-ignore */
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .account_number {
        /* prettier-ignore */
        width: 330pX;
        /* prettier-ignore */
        height: 50pX;
        background: #ffffff;
        border-radius: 5px;
        border: 2px solid #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        .inputbuton {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .obtaincode {
            /* prettier-ignore */
            font-size: 14pX;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #dd1d1e;
            cursor: pointer;
          }
          .inp {
            width: 100%;
          }
        }

        .cionimg {
          /* prettier-ignore */
          width: 14pX;
          /* prettier-ignore */
          height: 16pX;
          margin-right: 10px;
        }
        .inp {
          flex: 1;
          height: 92%;
          border: none;
          outline: none;
          /* prettier-ignore */
          font-size: 14pX;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
.login {
  .loigin_masking {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 98;
    left: 50%;
    /* prettier-ignore */
    margin-left: -400pX;
    top: 50%;
    /* prettier-ignore */
    margin-top: -250pX;
    .del_img {
      /* prettier-ignore */
      width: 30px;
      /* prettier-ignore */
      height: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    .leftimg {
      /* prettier-ignore */
      width: 412pX;
      /* prettier-ignore */
      height: 440pX;
    }
    .right_wif {
      /* prettier-ignore */
      width: 372pX;
      /* prettier-ignore */
      height: 440pX;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      .qrcode {
        width: 220px;
        height: 220px;
        background: #272636;
        margin-top: 41px;
      }
      .code_text {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        /* prettier-ignore */
        font-size: 16px;
        color: #333333;
        line-height: 24px;
        /* prettier-ignore */
        margin-top: 21px;
      }
      .close {
        position: absolute;
        /* prettier-ignore */
        right: 23pX;
        /* prettier-ignore */
        top: 23pX;
        /* prettier-ignore */
        width: 15pX;
        /* prettier-ignore */
        height: 14pX;
        cursor: pointer;
      }
      .log {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        .logtitle {
          /* prettier-ignore */
          font-size: 20pX;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          /* prettier-ignore */
          margin-bottom: 3pX;
        }
        .logtitle_tips {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          /* prettier-ignore */
          margin-bottom: 30pX;
        }
        .register_title {
          /* prettier-ignore */
          margin-bottom: 27pX;
          .title_one {
            /* prettier-ignore */
            font-size: 20pX;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .title_tow {
            /* prettier-ignore */
            font-size: 14pX;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .lstimg {
          /* prettier-ignore */
          margin-top: 21pX;
        }
        .rigimg {
          /* prettier-ignore */
          margin-top: 10pX;
        }

        .log_radio {
          display: flex;
          justify-content: center;
          align-items: center;

          .radio_text {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
            /* prettier-ignore */
            font-size: 12pX;
          }
        }
        .rightye {
          .reimg {
            /* prettier-ignore */
            width: 50pX;
            /* prettier-ignore */
            height: 50pX;
          }
        }
        .reye {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .rightye {
          text-align: center;
          /* prettier-ignore */
          font-size: 16pX;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          /* prettier-ignore */
          margin-top: 24pX;
        }
        .sumint {
          /* prettier-ignore */
          width: 330pX;
          /* prettier-ignore */
          height: 50pX;
          background: #472d86;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          font-size: 16pX;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          /* prettier-ignore */
          margin-top: 30pX;
          cursor: pointer;
        }
        .have {
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          margin-top: 11pX;
          /* prettier-ignore */
          margin-bottom: 37pX;
          /* prettier-ignore */
          font-size: 14pX;
          .cagolog {
            color: #472d86;
            cursor: pointer;
          }
        }
        .residuebut {
          display: flex;
          justify-content: center;
          align-items: center;
          /* prettier-ignore */
          font-size: 14pX;
          /* prettier-ignore */
          // margin-bottom: 70pX;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          // margin-top: 30px;
          .re_account {
            /* prettier-ignore */
            font-size: 14pX;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #472d86;
            cursor: pointer;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .black {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 97;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
  }
}
.active {
  font-family: SourceHanSansCN-Heavy, SourceHanSansCN !important;
  font-weight: 800 !important;
  color: #472d86 !important;
}
.nar {
  width: 100%;
  /* prettier-ignore */
  height: 100%;
}
.logon_hover:hover .contact {
  display: flex !important;
}
.contact {
  background: #fff;
  width: 188.87px;
  height: 186px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 60px;
  display: none;
  right: 120px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  .contact_title {
    margin-bottom: 10px;
  }
  .contact_img {
    width: 94px;
    height: 94px;
    margin-bottom: 10px;
  }
}
.topnar {
  width: 100%;
  /* prettier-ignore */
  height: 100%;
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 200px;
  box-sizing: border-box;
  .top_right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top_left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    /* prettier-ignore */
    font-size: 18px;
    color: #472d86;
    line-height: 30px;
    white-space: nowrap;
  }
  .narlist {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-right: 20px;
    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      .searchinput::-webkit-input-placeholder {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        /* prettier-ignore */
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
      .searchinput {
        width: 500px;
        // border:1px solid;

        /* prettier-ignore */
        height: 58px;
        line-height: 58px;
        font-size: 16px;
        background: #ffffff;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #f1f1f1;
        padding: 0 20px;
      }
      .search_box {
        /* prettier-ignore */
        width: 60px;
        /* prettier-ignore */
        height: 60px;
        background: #dd1d1e;
        border-radius: 0px 4px 4px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .search_img {
          /* prettier-ignore */
          width: 20px;
          /* prettier-ignore */
          height: 20px;
        }
      }
    }
    .naritem {
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      margin-left: 40px;
      /* prettier-ignore */
      font-size: 16pX;
    }
  }
  .topimg {
    /* prettier-ignore */
    width: 170px;
    /* prettier-ignore */
    height: 52px;

    cursor: pointer;
    margin-right: 14px;
  }
}
</style>
