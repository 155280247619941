/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-19 18:11:49
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-07-24 16:32:41
 * @FilePath: \my-project\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index/index.vue'
import layout from '../views/layout/layout.vue'
import admin from '../views/admin/index.vue'
import morelist from '../views/morelist/index.vue'
import search from '../views/search/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/admin',
        name: 'admin',
        component: admin,
      },
      {
        path: '/morelist',
        name: 'morelist',
        component: morelist,
      },
      {
        path: '/search',
        name: 'search',
        component: search,
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: '/index/',
  routes
})

export default router
