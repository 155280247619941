<template>
  <div>
    <el-container>
      <el-header>
        <HeadBar></HeadBar>
      </el-header>
      <el-container width="200px">
        <AppMain></AppMain>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { HeadBar, AppMain } from "./components";
export default {
  components: {
    HeadBar,
    AppMain,
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  height: 80px !important;
  padding: 0 0px;
}
.conta {
  width: 100%;
  background: #ececf3;
}
</style>
