/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-19 18:11:49
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-09 11:47:19
 * @FilePath: \my-project\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import "swiper/swiper-bundle.css";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios'
import qs from 'qs'
Vue.prototype.$http = axios
Vue.prototype.$qs = qs

Vue.use(ElementUI);
//页面顶部
import topnar from './components/topnar.vue'
Vue.component('topnar', topnar);
//页面底部
import buton from './components/buttonlin.vue'
Vue.component('buton', buton);
//页面底部
import upward from './components/upward.vue'
Vue.component('upward', upward);
//页面弹窗
import download from './components/download.vue'
Vue.component('download', download);
//页面轮播
// import swiperr from './components/swiperr.vue'
// Vue.component('swiperr', swiperr);

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.prototype.handleCurrentChange = function (Fname, Cname, Cid, val) {
  router.push({
    url: router,
    query: {
      page: val,
      Fname: Fname,
      Cname: Cname,
      Cid: Cid
    }
  });

}
Vue.prototype.handleCurrentChangese = function (inptext, val) {
  router.push({
    url: router,
    query: {
      page: val,
      sease: inptext,
    }
  });

}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
