<template>
  <div class="download">
    <div class="download_bock" v-if="bockShow"></div>
    <div class="downloadye" v-if="yeShow">
      <img src="../assets/icon/xiazaic_gg@2x.png" class="yeimg" alt="" />
      <div class="yetips">下载成功</div>
      <div class="yetext">注：报告将到“我的主页-我的下载”中查看下载</div>
      <div class="displaybot">
        <div class="button onone" @click="(yeShow = false), (bockShow = false)">
          查看报告
        </div>
        <div class="button ontow" @click="gouser">去我的主页</div>
      </div>
    </div>
    <div class="downloadvip" v-if="vipShow">
      <div class="yetips">
        您当前是普通会员，无法下载资料内容，需要升级为VIP才能下载资料。
      </div>
      <div
        class="yetext viptips"
        style="display: flex; align-items: center; justify-content: center"
      >
        <p class="viptext" style="color: #dd1d1e; margin: 0px">VIP</p>
        事宜请联系管理员：150 1092 1500（王老师，手机同微信号）。
      </div>
      <img src="../assets/icon/xiazaic_gg@2x.png" class="vipimg" alt="" />
      <div class="viptips">客服微信</div>
      <div class="vipbut" @click="(vipShow = false), (bockShow = false)">
        确定
      </div>
    </div>
    <div class="downloadnum" v-if="NumShow">
      <div class="yetips">今日下载次数已达上限</div>
      <div
        class="yetext viptips"
        style="display: flex; align-items: center; justify-content: center"
      >
        <p>尊敬的</p>
        <p class="viptext" style="color: #dd1d1e; margin: 0px">VIP</p>
        请明天再次下载
      </div>
      <div class="vipbut" @click="(NumShow = false), (bockShow = false)">
        确定
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  props: {},
  data() {
    return {
      bockShow: false,
      yeShow: false,
      vipShow: false,
      NumShow: false,
    };
  },
  created() {
    window.addEventListener("yeShow", () => {
      this.yeShow = true;
      this.bockShow = true;
    });
    window.addEventListener("vipShow", () => {
      this.vipShow = true;
      this.bockShow = true;
    });
    window.addEventListener("NumShow", () => {
      this.NumShow = true;
      this.bockShow = true;
    });
  },
  methods: {
    gouser() {
      let currentPath = window.location.pathname;
      if (currentPath != "/index/admin") {
        this.$router.push("/admin");
      }
    },
  },
};
</script>
    
    <style scoped lang="scss">
.download {
  .download_bock {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 97;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
  }
  .downloadnum {
    position: fixed;
    z-index: 98;
    /* prettier-ignore */
    width: 500px;
    /* prettier-ignore */
    height: 420px;
    background: #ffffff;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    /* prettier-ignore */
    margin-left: -250px;
    /* prettier-ignore */
    margin-top: -210px;
    /* prettier-ignore */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .viptips {
      color: #333333;
      font-weight: bold;
      /* prettier-ignore */
      margin: 13px 0 31px;
    }
  }
  .downloadvip {
    position: fixed;
    z-index: 98;
    /* prettier-ignore */
    width: 780px;
    /* prettier-ignore */
    height: 420px;
    background: #ffffff;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    /* prettier-ignore */
    margin-left: -390px;
    /* prettier-ignore */
    margin-top: -210px;
    /* prettier-ignore */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .viptips {
      color: #333333;
      font-weight: bold;
      /* prettier-ignore */
      margin: 13px 0 31px;
    }
  }
  .downloadye {
    position: fixed;
    z-index: 98;
    /* prettier-ignore */
    width: 570px;
    /* prettier-ignore */
    height: 344px;
    background: #ffffff;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    /* prettier-ignore */
    margin-left: -290px;
    /* prettier-ignore */
    margin-top: -192px;
    /* prettier-ignore */
    padding: 0 118px;
    box-sizing: border-box;
  }
  .vipbut {
    /* prettier-ignore */
    width: 330px;
    /* prettier-ignore */
    height: 50px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dd1d1e;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    /* prettier-ignore */
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
  }
  .viptips {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    /* prettier-ignore */
    font-size: 14px;
    color: #333333;
    line-height: 21px;
  }
  .vipimg {
    /* prettier-ignore */
    width: 119px;
    /* prettier-ignore */
    height: 119px;
    /* prettier-ignore */
  }
  .yeimg {
    /* prettier-ignore */
    width: 52px;
    /* prettier-ignore */
    height: 52px;
    /* prettier-ignore */
    margin: 52px 0px 24px;
  }
  .yetips {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 600;
    /* prettier-ignore */
    font-size: 16px;
    color: #333333;
    line-height: 21px;
  }
  .yetext {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    /* prettier-ignore */
    font-size: 14px;
    color: #999999;
    line-height: 21px;
    /* prettier-ignore */
    margin-bottom: 47px;
  }
  .displaybot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      /* prettier-ignore */
      width: 160px;
      /* prettier-ignore */
      height: 50px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .onone {
      background: #e3e3e3;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      /* prettier-ignore */
      font-size: 16px;
      color: #333333;
      line-height: 24px;
    }
    .ontow {
      background: #dd1d1e;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      /* prettier-ignore */
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
    }
  }
  .yeimg {
    /* prettier-ignore */
    width: 52px;
    /* prettier-ignore */
    height: 52px;
  }
}
</style>