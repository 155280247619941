<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-20 09:18:20
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 14:36:57
 * @FilePath: \my-project\src\views\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <div class="search">
      <input
        class="searchinput"
        v-model="inptext"
        placeholder="请输入搜索内容"
        type="text"
        @keydown="seceinpt"
      />
      <div class="search_box" @click="clikseceinpt">
        <img class="search_img" src="../../assets/icon/20_dss@2x.png" alt="" />
      </div>
    </div>
    <div class="wf_title">
      <div class="title_item">
        共搜索到相关结果
        <p class="item_tow">{{ total_articles }}</p>
        个
      </div>
    </div>
    <div class="wf_box">
      <div class="wf_announcement">
        <div class="wf_head">
          <div class="wf_titlee">标题</div>
          <div class="wf_time">类型</div>
          <div class="wf_tzr">操作</div>
        </div>
        <div class="wf_list">
          <div class="wf_item" v-for="(item, index) in datalist" :key="index">
            <div class="item_left">
              <div class="dian"></div>
              <div class="left_text" v-if="item.title.length > 1">
                {{ item.title[0] }}<span style="color: #dd1d1e">{{ lookKey }}</span
                >{{ item.title[1] }}
              </div>
              <div class="left_text" v-else>
                {{ item.title[0] }}
              </div>
            </div>
            <div class="item_time">
              <span v-if="item.type">{{ item.type.toUpperCase() }}</span>
              <!-- .toUpperCase() -->
            </div>
            <div class="item_text" @click="goboback(item)">
              <img class="text_right" src="../../assets/icon/xiaza_sa@2x.png" alt="" />
              下载
            </div>
          </div>
        </div>
        <div class="elbot_box" v-if="total_articles != 0">
          <el-pagination
            class="elbot"
            background
            layout="prev, pager, next"
            @current-change="(val) => handleCurrentChangese(inptext, val)"
            :current-page.sync="current_page"
            :total="total_articles"
            :page-size="10"
          >
          </el-pagination>
        </div>
        <div class="nomore" v-if="datalist.length == 0">
          <img class="nomore_img" src="../../assets/img/nomore.png" alt="" />
          <p>暂无内容</p>
        </div>
      </div>
    </div>
    <upward></upward>
    <buton></buton>
    <download></download>
  </div>
</template>

<script>
import { basegetMyList, useredit } from "@/api/base";
import { usersinfo } from "@/api/logoin";
import { uploadfile, articlesgetList, Getdown } from "@/api/index";
export default {
  data() {
    return {
      lookKey: "",
      inptext: "",
      spde: 0.5,
      imgdata: [],
      tableData: [],
      headindex: 1,
      page: 1,
      datalist: [],
      loadingAdd: false,
      moreshow: 1,
      infoindex: 1,
      userdata: {
        user_pic: "",
        user_name: "",
        login_phone: "",
      },
      edittype: "",
    };
  },
  watch: {
    // 监听路由变化，随时获取新的列表信息
    $route: "fetchData",
  },
  methods: {
    goboback(item) {
      console.log(item);
      Getdown({
        id: item.id,
      }).then((res) => {
        console.log(res);
        if (res.data.code == "10000") {
          window.open(res.data.data.url, "_blank");
          window.dispatchEvent(new CustomEvent("yeShow", {}));
        } else if (res.data.code == "10009") {
          window.dispatchEvent(new CustomEvent("vipShow", {}));
        }
      });
    },
    editinputce() {
      this.edittype = true;
    },
    upedit() {},
    editinput() {
      console.log("========");
      this.edittype = false;
      useredit({
        user_name: this.userdata.user_name,
      }).then((res) => {
        console.log(res);
        window.dispatchEvent(new CustomEvent("user", {}));
      });
    },
    showImgToView(event) {
      console.log(event, "file");
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.value);
      uploadfile(formData).then((res) => {
        console.log(res);
        this.userdata.user_pic = res.data.data;
        useredit({
          user_pic: res.data.data,
        }).then((res) => {
          console.log(res);
          window.dispatchEvent(new CustomEvent("user", {}));
        });
      });
    },
    seceinpt(e) {
      if (e.keyCode == 13) {
        this.init();
      }
    },
    clikseceinpt() {
      this.init();
    },
    goinfo(item) {
      this.$router.push({ path: "info", query: { id: item.id } });
    },
    page_turning() {
      this.page++;
      this.init();
    },
    infoindexce(val) {
      this.infoindex = val;
      this.page = 1;
      this.init();
    },
    headindexce(val) {
      this.headindex = val;
      this.page = 1;
      this.init();
    },
    liat() {
      usersinfo().then((res) => {
        console.log(res);
        this.userdata = res.data.data;
      });
    },
    fetchData() {
      this.page = this.$route.query.page || 1;
      this.init();
    },
    init() {
      this.lookKey = this.inptext;
      articlesgetList({
        limit: "10",
        page: this.page,
        keyword: this.inptext,
      })
        .then((res) => {
          console.log(res);
          this.total_articles = res.data.data.total;
          this.datalist = res.data.data.data;
          for (let i = 0; i < this.datalist.length; i++) {
            this.datalist[i].title = this.datalist[i].title.split(this.inptext);
            console.log(this.datalist[i].title);
          }

          if (res.data.data.current_page >= res.data.data.last_page) {
            this.moreshow = 2;
          }
          if (this.datalist.length == 0) {
            this.moreshow = 3;
          }
        })
        .catch((err) => {
          this.$message({
            message: "网络请求超时",
            iconClass: "true",
            center: "true",
          });
          return;
        });
    },
  },
  created() {
    window.dispatchEvent(new CustomEvent("searchShowFa", {}));
    this.inptext = this.$route.query.sease;
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
      this.current_page = this.$route.query.page;
    }
  },
  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log("iPhone");
      this.spde = 0.2;
    } else if (/(Android)/i.test(navigator.userAgent)) {
      console.log("Android");
      this.spde = 0.2;
    } else {
      console.log("pc");
      this.spde = 0.5;
    }
    this.init();
    this.liat();
  },
  computed: {
    optionSetting() {
      return {
        step: this.spde, // 数值越大速度滚动越快
        limitMoveNum: this.tableData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  position: relative;
  .search {
    display: flex;
    align-items: center;
    padding: 30px 200px 0px;
    .searchinput::-webkit-input-placeholder {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      /* prettier-ignore */
      font-size: 16px;
      color: #333333;
      line-height: 22px;
    }
    .searchinput {
      /* prettier-ignore */
      width: 734px;
      /* prettier-ignore */
      height: 58px;
      font-size: 16px;
      background: #ffffff;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #f1f1f1;
      padding: 0 20px;
    }
    .search_box {
      cursor: pointer;
      /* prettier-ignore */
      width: 60px;
      /* prettier-ignore */
      height: 60px;
      background: #dd1d1e;
      border-radius: 0px 4px 4px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      .search_img {
        /* prettier-ignore */
        width: 20px;
        /* prettier-ignore */
        height: 20px;
      }
    }
  }
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
/deep/.el-pager {
  .active {
    background-color: rgb(221, 40, 36) !important;
  }
  .number:hover {
    color: rgb(221, 40, 36) !important;
  }
}
.elbot_box {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  .elbot {
    margin: 40px 0px 0px 0px !important;
    position: absolute;
    bottom: 20px;
  }
}
.nomore {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin-top: 20px;
  .nomore_img {
    width: 121px;
    height: 108px;
  }
}
/deep/.el-carousel__container {
  height: auto !important;
}
.wf_box {
  padding: 0 200px 37px;
  box-sizing: border-box;
  margin-top: 15px;
  .wf_bot {
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px !important;
  }
  .wf_announcement {
    min-height: 350px;
    max-height: 750px;
    background: #fff;
    position: relative;
    border-radius: 6px;
    .wf_head {
      height: 60px;
      background: #f0f0f0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      font-weight: 600;
      .wf_titlee {
        width: 70%;
        display: flex;
      }
      .wf_time {
        width: 25%;
        display: flex;
      }
      .wf_tzr {
        width: 5%;
        display: flex;
      }
    }
    .wf_list {
      display: flex;
      flex-direction: column;
      .wf_item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e9;
        padding: 20px 0px;
        .item_time {
          width: 22.5%;
          flex-shrink: 0;
          text-align: left;
        }
        .item_text {
          // border: 1px solid;
          // width: 15%;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          /* prettier-ignore */
          font-size: 16px;
          color: #dd1d1e;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          flex-shrink: 0;
          .text_type {
            width: 100px;
            height: 24px;
            background: #efedf4;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 12px;
            color: #472d86;
          }
          .text_right {
            width: 13px;
            height: 14px;
            // margin-left: 9px;
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
          }
        }
        .item_left {
          display: flex;
          align-items: center;
          width: 69%;
          flex-shrink: 0;

          .left_text {
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          .dian {
            /* prettier-ignore */
            width: 6px;
            height: 6px;
            background: #cbcbcb;
            border-radius: 50%;
            /* prettier-ignore */
            margin: 0 15px;
          }
        }
      }
    }
    .wf_search {
      width: 300px;
      height: 40px;
      background: #f8f8f8;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 14px;
      margin-top: 14px;
      .search_img {
        width: 14px;
        height: 14px;
        margin-right: 14px;
      }
      .search_inp {
        border: none;
        flex: 1;
        background: #f8f8f8;
      }
      .search_but {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        font-size: 16px;
        color: #472d86;
      }
    }

    .announcement_text {
      margin-left: 18px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
    }
  }
}
.wf_title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 200px;
  box-sizing: border-box;
  padding-top: 30px;
  .title_item {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    /* prettier-ignore */
    font-size: 14px;
    color: #999999;
    height: 30px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    .item_one {
      margin-left: 3px;
    }
    .item_tow {
      margin-left: 3px;
      color: #dd1d1e;
      font-weight: 600;
    }
  }
  .title_active {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    flex-direction: column;
    .wf_buton {
      width: 16px;
      height: 4px;
      background: #472d86;
      border-radius: 9px;
      margin-top: 5px;
    }
  }
}

.form_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 120px;
  box-sizing: border-box;
  /* prettier-ignore */
  margin-top: 30px;
  margin-bottom: 30px;
  .title_left {
    width: 4px;
    height: 18px;
    background: #472d86;
    border-radius: 2px;
    margin-right: 12px;
  }
  .title_right {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    .right_icon {
      width: 16px;
      height: 12px;
      margin-right: 12px;
    }
  }
}
</style>
