<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-20 16:39:03
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 09:45:14
 * @FilePath: \my-project\src\components\buttonlin.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="buton">
      <div class="buback">
        <div class="company_Profile">
          <img class="profile_img" src="../assets/icon/logo@2x.png" alt="" />
          <div class="profile_list">
            <div class="profile_item">
              <img
                class="item_img"
                src="../assets/icon/dizhi_ad@2x.png"
                alt=""
              />
              地址：{{ comData.address }}
            </div>
            <div class="profile_item">
              <img
                class="item_img"
                src="../assets/icon/dianhu_ad@2x.png"
                alt=""
                style="width: 14px; height: 14px"
              />
              电话：{{ comData.tel }}
            </div>
            <div class="profile_item">
              <img
                class="item_img"
                src="../assets/icon/youxia_sa@2x.png"
                alt=""
                style="width: 16px; height: 14px"
              />
              邮箱：{{ comData.email }}
            </div>
          </div>
        </div>
        <div class="code">{{ comData.ICP }}</div>
      </div>
    </div>
    <div class="die"></div>
  </div>
</template>

<script>
import { cmsSiteLinks } from "@/api/bottom";
import { commonconfig } from "@/api/index";

export default {
  data() {
    return {
      dataliast: [],
      comData: [],
    };
  },
  methods: {
    gowb(hefr) {
      const w = window.open("about:blank");
      w.location.href = hefr;
    },
    gourl(item) {
      window.open(item.link_to, "_back");
    },
    init() {
      commonconfig().then((res) => {
        this.comData = res.data.data;
      });
  
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.die {
  /* prettier-ignore */
  height: 142PX;
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
.buton {
  width: 100%;
  position: absolute;
  bottom: 0px;
  .buback {
    width: 100%;
    /* prettier-ignore */
    height: 142PX;
    // padding: 0 120px;
    box-sizing: border-box;
    background: #efefef;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-end;
    .company_Profile {
      text-align: left;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      padding: 0 200px;
      box-sizing: border-box;
      /* prettier-ignore */
      height: 141px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .profile_list {
        display: flex;
        justify-content: center;
        align-items: center;
        .profile_item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          white-space: nowrap;
          font-weight: 400;
          /* prettier-ignore */
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          /* prettier-ignore */
          margin-left: 18px;

          .item_img {
            /* prettier-ignore */
            width: 11px;
            /* prettier-ignore */
            height: 14px;
            margin-right: 8px;
          }
        }
      }
      .profile_img {
        /* prettier-ignore */
        width: 170px;
        /* prettier-ignore */
        height: 52px;
      }
      .Profile_title {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: bold;
        /* prettier-ignore */
        font-size: 14px;
        color: #472d86;
        /* prettier-ignore */
        margin-bottom: 10px;
      }

      .Profile_item:first-child {
        padding-left: 0px !important;
      }
      .Profile_item:last-child {
        border-right: none !important;
      }
      .Profile_list {
        display: flex;
        align-content: center;
        .Profile_item:hover {
          color: #472d86;
        }
        .Profile_item {
          line-height: 1 !important;
          padding-right: 10px;
          padding-left: 10px;
          border-right: 1px solid #333333;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          cursor: pointer;
          /* prettier-ignore */
          font-size: 14px;
          color: #333333;
          line-height: 21px;
        }
      }
    }
    .code {
      width: 100%;
      text-align: left;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      background: #d3d3d3;
      /* prettier-ignore */
      height: 32px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      /* prettier-ignore */
      font-size: 12px;
      color: #333333;
      line-height: 17px;
    }
    .img {
      width: 146px;
      height: 38px;
      margin-bottom: 18px;
    }
  }
  .linklist {
    width: 100%;
    /* prettier-ignore */
    height: 100pX;
    padding: 0 120px;
    box-sizing: border-box;
    background: #f5f6f9;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    .lin_title {
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 18px;
      /* prettier-ignore */
      font-size: 16pX;
    }
    .inlist {
      display: flex;
      justify-content: center;
      align-items: center;
      .in_item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        /* prettier-ignore */
        font-size: 14pX;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
      }
      .in_item:hover {
        color: #c32227;
        text-decoration: underline;
      }
    }
  }
}
</style>