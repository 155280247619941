<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-21 11:15:49
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-09 10:17:04
 * @FilePath: \my-project\src\components\upward.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <!-- <img v-if="show"  @click="gotop" class="iconimg" src="../assets/img/fanh_ds_b.png" alt="" /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    gotop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    var _this = this;
    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (scrollTop > windowHeight) {
        _this.show = true;
      } else {
        _this.show = false;
      }
    };
  },
};
</script>

<style scoped lang="scss">
.iconimg {
  /* prettier-ignore */
  width: 70pX;
  /* prettier-ignore */
  height: 70pX;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
}
@media screen and (max-width: 1000px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
  .iconimg {
    right: 100px;
    width: 150px !important;
    height: 150px !important;
    bottom: 400px !important;
  }
}
@media screen and (max-width: 1280px) {
  .pcbig {
    display: none !important;
  }

  .pcmin {
    display: block !important;
  }
}
</style>
