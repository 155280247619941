<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-07-20 09:18:20
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-23 14:36:57
 * @FilePath: \my-project\src\views\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <!-- <topnar nar="首页"></topnar> -->
    <div class="index_box">
      <div class="toplsit">
        <div class="swiper">
          <el-carousel
            arrow="hover"
            :indicator-position="imgdata.length > 1 ? '' : 'none'"
            trigger="click"
            class="swiperlist"
          >
            <el-carousel-item v-for="item in imgdata" :key="item.url" class="swiperitem">
              <img class="img" :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>

          <div class="search">
            <input
              class="searchinput"
              placeholder="请输入搜索内容"
              v-model="inptext"
              type="text"
              @keydown="seceinpt"
            />
            <div class="search_box" @click="clikseceinpt">
              <img class="search_img" src="../../assets/icon/20_dss@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="listFlex">
        <div class="list_item">
          <div class="list_text">
            <img class="title_img" src="../../assets/icon/zial_k@2x.png" alt="" />
            <span class="leve1">
              {{ listData[0].name }}
            </span>
          </div>
          <div class="item_data">
            <div
              class="ite_ite"
              v-for="(item, index) in listData[0].children"
              :key="index"
              @click="gomore(listData[0].name, item)"
            >
              <template v-if="index < 8">
                <div class="ite_left">
                  <div class="dian"></div>
                  <div class="item_text">{{ item.name }}</div>
                </div>
                <img class="ite_right" src="../../assets/icon/da_aw.png" alt="" />
              </template>
            </div>
          </div>
        </div>
        <div class="list_item">
          <div class="list_text">
            <img class="title_img" src="../../assets/icon/caht_p@2x.png" alt="" />
            <span class="leve1">
              {{ listData[1].name }}
            </span>
          </div>
          <div class="item_data">
            <div
              class="ite_ite"
              v-for="(item, index) in listData[1].children"
              :key="index"
              @click="gomore(listData[1].name, item)"
            >
              <template v-if="index < 8">
                <div class="ite_left">
                  <div class="dian"></div>
                  <div class="item_text">{{ item.name }}</div>
                </div>
                <img class="ite_right" src="../../assets/icon/da_aw.png" alt="" />
              </template>
            </div>
          </div>
        </div>
        <div class="list_item">
          <div class="list_text">
            <img class="title_img" src="../../assets/icon/yanj_bg@2x.png" alt="" />
            <span class="leve1">
              {{ listData[2].name }}
            </span>
          </div>
          <div class="item_data">
            <div
              class="ite_ite"
              v-for="(item, index) in listData[2].children"
              :key="index"
              @click="gomore(listData[2].name, item)"
            >
              <template v-if="index < 8">
                <div class="ite_left">
                  <div class="dian"></div>
                  <div class="item_text">{{ item.name }}</div>
                </div>
                <img class="ite_right" src="../../assets/icon/da_aw.png" alt="" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <upward></upward>
    <buton></buton>
  </div>
</template>

<script>
import { imgcarousel, getTreeList, getTestToken } from "@/api/index";
export default {
  data() {
    return {
      inptext: "",
      spde: 0.5,
      imgdata: [],
      tableData: [],
      page: 1,
      datalist: [],
      loadingAdd: false,
      moreshow: 1,
      content: "",
      listData: [],
    };
  },
  methods: {
    seceinpt(e) {
      if (e.keyCode == 13) {
        this.$router.push({
          path: "search",
          query: { userId: "", sease: this.inptext },
        });
      }
    },
    gomore(fname, item) {
      let pathHerf = this.$router.resolve({
        path: "morelist",
        query: { Fname: fname, Cname: item.name, Cid: item.id },
      });
      window.open(pathHerf.href, "_blank");
      // this.$router.push({
      //   path: "morelist",
      //   query: { Fname: fname, Cname: item.name, Cid: item.id },
      // });
    },
    clikseceinpt() {
      this.$router.push({
        path: "search",
        query: { sease: this.inptext },
      });
    },
    goinfo(item) {
      this.$router.push({ path: "info", query: { id: item.id } });
    },
    page_turning() {
      this.page++;
      this.init();
    },
    init() {
      if (this.moreshow == 2) {
        return 2;
      }
    },
    liat() {
      imgcarousel({
        id: 1,
      })
        .then((res) => {
          console.log(res, "----------------------");
          let data = res.data.data.images;

          this.imgdata = data.sort(function (a, b) {
            return a.sort - b.sort;
          });
        })
        .catch((err) => {
          console.log(err);
          return;
        });
      // getTestToken().then((res) => {
      //   console.log(res, "token");
      //   sessionStorage.setItem("cc_token", res.data.data.token);
      // });
      getTreeList().then((res) => {
        console.log(res.data.data);
        this.listData = res.data.data;
      });
      // articlesgetList({
      //   category_id: 8,
      // })
      //   .then((res) => {
      //     console.log(res);
      //     // this.imgdata = res.data.data.images;
      //     this.content = res.data.data.data[0].title;
      //   })
      //   .catch((err) => {
      //     this.$message({
      //       message: "网络请求超时",
      //       iconClass: "true",
      //       center: "true",
      //     });
      //     return;
      //   });
    },
  },
  created() {
    window.dispatchEvent(new CustomEvent("searchShowFa", {}));
  },
  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log("iPhone");
      this.spde = 0.2;
    } else if (/(Android)/i.test(navigator.userAgent)) {
      console.log("Android");
      this.spde = 0.2;
    } else {
      console.log("pc");
      this.spde = 0.5;
    }
    this.init();
    this.liat();
  },
  computed: {
    optionSetting() {
      return {
        step: this.spde, // 数值越大速度滚动越快
        limitMoveNum: this.tableData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  position: relative;
  .index_box {
    padding: 37px 200px 37px;
    box-sizing: border-box;
  }
  .listFlex {
    display: flex;
    justify-content: space-between;
    /* prettier-ignore */
    margin-top: 50px;
    .list_item {
      width: 330px;
      white-space: nowrap;
      .item_data {
        .ite_ite {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          cursor: pointer;
          font-weight: 600;
          /* prettier-ignore */
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          /* prettier-ignore */
          height: 50px;
          .ite_left {
            display: flex;
            justify-content: center;
            align-items: center;
            .item_text {
              max-width: 270px;
              overflow: hidden; //超出的文本隐藏

              text-overflow: ellipsis; //溢出用省略号显示

              white-space: nowrap; //溢出不换行
            }
          }
          .ite_right {
            /* prettier-ignore */
            width: 8px;
            /* prettier-ignore */
            height: 14px;
            margin-right: 8px;
          }
          .dian {
            width: 6px;
            height: 6px;
            background: #cbcbcb;
            border-radius: 50%;
            margin: 0 8px;
          }
        }
      }
      .list_text {
        .leve1 {
          max-width: 300px;
          overflow: hidden; //超出的文本隐藏

          text-overflow: ellipsis; //溢出用省略号显示

          white-space: nowrap; //溢出不换行
        }
        /* prettier-ignore */
        height: 60px;
        background: #f0f0f0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* prettier-ignore */
        padding: 0 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        /* prettier-ignore */
        font-size: 20px;
        color: #333333;
        line-height: 28px;
        .title_img {
          /* prettier-ignore */
          width: 21px;
          /* prettier-ignore */
          height: 22px;
          /* prettier-ignore */
          margin-right: 8px;
        }
      }
    }
  }
}
.pcbig {
  display: block;
}
.pcmin {
  display: none;
}
/deep/.el-carousel__container {
  height: 100% !important;
}
/deep/.el-carousel__arrow--left {
  width: 80px;
  height: 80px;
}
/deep/.el-carousel__arrow--right {
  width: 80px;
  height: 80px;
}
/deep/.el-icon-arrow-left::before {
  font-size: 24px !important;
}
/deep/.el-icon-arrow-right::before {
  font-size: 24px !important;
}

.more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 0;
  .more_cen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .cen_text {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .cenicon {
      .img {
        width: 14px;
        height: 10px;
      }
    }
  }
}

.swiper {
  width: 100%;
  /* prettier-ignore */
  height: 350px;
  margin-left: 0px !important;
  position: relative;
  .search {
    position: absolute;
    z-index: 99;
    top: 180px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    .searchinput::-webkit-input-placeholder {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      /* prettier-ignore */
      font-size: 16px;
      color: #333333;
      line-height: 22px;
    }
    .searchinput {
      width: 400px;
      /* prettier-ignore */
      font-size: 16px;
      height: 50px;
      background: #ffffff;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #f1f1f1;
      padding: 0 20px;
    }
    .search_box {
      /* prettier-ignore */
      width: 52px;
      /* prettier-ignore */
      height: 52px;
      background: #dd1d1e;
      border-radius: 0px 4px 4px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .search_img {
        /* prettier-ignore */
        width: 20px;
        /* prettier-ignore */
        height: 20px;
      }
    }
  }
  .swiperlist {
    width: 100%;
    /* prettier-ignore */
    height: 350px;

    .swiperitem {
      width: 100%;
      /* prettier-ignore */
      height: 350px;
      border-radius: 5px;
    }
  }
  .img {
    width: 100%;
    height: 100%;
  }
}
</style>
