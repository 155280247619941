/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 16:23:50
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-16 17:52:34
 * @FilePath: \my-project\src\api\logoin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引用封装的 request
import request from '@/js/request'

//获取验证码
export const gotcode = (data) => {
    return request({
        url: "/api/user/sendCode",
        method: "POST",
        data,
    });
};

//注册
export const getenroll = (data) => {
    return request({
        url: "api/login/enroll",
        method: "POST",
        data,
    });
};


//登录
export const mobilePhoneLogin = (data) => {
    return request({
        url: "api/user/phoneNumberLogin",
        method: "POST",
        data,
    });
};


//个人信息
export const usersinfo = (data) => {
    return request({
        url: "/api/user/info",
        method: "POST",
        data,
    });
};


//更新用户信息
export const usersupdate = (data) => {
    return request({
        url: "/api/user/update",
        method: "POST",
        data,
    });
};

//获取用户下载
export const medown = (data) => {
    return request({
        url: "/api/user/down",
        method: "POST",
        data,
    });
};
export const wxLogin = (data) => {
    return request({
        url: '/api/user/wechatLogin',
        method: "POST",
        data,
    })
}
