<!--
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-04-22 15:28:30
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-03 12:24:07
 * @FilePath: \my-project\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  provide() {
    return {};
  },
  data() {
    return {
      scale: 1,
    };
  },
  mounted() {
    document.getElementById("app").onmousewheel = (event) => {
      var dir = event.deltaY > 0 ? "Up" : "Down";
      if (dir == "Up" && event.ctrlKey === true) {
        console.log("向上滑动");
        this.zoomin();
      } else if (dir == "Down" && event.ctrlKey === true) {
        console.log("向下滑动");
        this.zoomout();
      }
    };
  },
  methods: {
    zoomout() {
      console.log("out");
      document.getElementById("app").style.transform = "scale(" + this.scale + ")";
      this.scale += 0.05;
    },
    zoomin() {
      console.log("min");
      document.getElementById("app").style.transform = "scale(" + this.scale + ")";
      this.scale -= 0.05;
      if (this.scale < 0.5) {
        this.scale = 0.5;
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

a:focus,
input:focus,
p:focus,
div:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-modify: read-write-plaintext-only;
  outline: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  width: 100%;
  height: 100%;
}

html::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

html::-webkit-scrollbar-thumb {
  background-color: #472d86;
}

.px14 {
  font-size: 14px;
}
.px16 {
  font-size: 16px;
}
.px18 {
  font-size: 18px;
}
.px19 {
  font-size: 19px;
}
.px20 {
  font-size: 20px;
}
.px21 {
  font-size: 21px;
}
.px22 {
  font-size: 22px;
}
.px27 {
  font-size: 27px;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

nav {
  padding: 0.3rem;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
