/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 16:23:50
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-16 17:52:34
 * @FilePath: \my-project\src\api\logoin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引用封装的 request
import request from '@/js/request'

//获取基地申报\变更\延续列表
export const basegetList = (data) => {
    if(!sessionStorage.getItem('cc_token')){
//return {then:()=>{},catch:()=>{}};
    }
    return request({
        url: "/api/base/getList",
        method: "POST",
        data, 
    });
};


//获取基地申报详情
export const basegetDetail = (data) => {
    return request({
        url: "/api/base/getDetail",
        method: "POST",
        data, 
    });
};

//获取基地申报提交
export const basesubmit = (data) => {
    return request({
        url: "/api/base/submit",
        method: "POST",
        data, 
    });
};


//获取基地展示
export const getSuccessList = (data) => {
    return request({
        url: "/api/base/getSuccessList",
        method: "POST",
        data, 
    });
};


//获取基地展示
export const basegetMyList = (data) => {
    return request({
        url: "/api/base/getMyList",
        method: "POST",
        data, 
    });
};


//修改用户信息
export const useredit = (data) => {
    return request({
        url: "/api/user/edit",
        method: "POST",
        data, 
    });
};
