/*
 * @Author: gaoda 1622658376@qq.com
 * @Date: 2023-05-11 16:23:50
 * @LastEditors: gaoda 1622658376@qq.com
 * @LastEditTime: 2023-08-17 15:19:08
 * @FilePath: \my-project\src\api\logoin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引用封装的 request
import request from '@/js/request'


//轮播图
export const imgcarousel = (data) => {
    return request({
        url: "api/images/carousel",
        method: "POST",
        data, 
    });
};

//获取文章
export const articlesgetList = (data) => {
    return request({
        url: "api/cms/articles/getList",
        method: "POST",
        data, 
    });
};

//搜索公示文件列表
export const publicFilelist= (data) => {
    return request({
        url: "/api/publicFile/list",
        method: "POST",
        data, 
    });
};

//上传图片
export const uploadfile = (data) => {
    return request({
        url: "/api/upload/file",
        method: "POST",
        data, 
    });
};

//获取栏目树级列表
export const getTreeList = (data) => {
    return request({
        url: "/api/cms/category/getTreeList",
        method: "POST",
        data, 
    });
};

//获取文章详情
export const articlesgetDetail = (data) => {
    return request({
        url: "/api/cms/articles/getDetail",
        method: "POST",
        data, 
    });
};


//获取基础配置
export const commonconfig = (data) => {
    return request({
        url: "/api/common/config",
        method: "POST",
        data, 
    });
};


//下载
export const Getdown = (data) => {
    return request({
        url: "/api/cms/down",
        method: "POST",
        data, 
    });
};

//测试toke
export const getTestToken = (data) => {
    return request({
        url: "/api/user/getTestToken",
        method: "POST",
        data, 
    });
};

